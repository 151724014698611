
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
};

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .input-gradient { 
    border-image: linear-gradient(45deg, #7ebbec, #009ee3) 1; 
    animation: borderAnimation 5s infinite; 
  }
  @keyframes borderAnimation {
    0% {
      border-image-source: linear-gradient(45deg, #b1d6f5, #009EE3);
    }
    50% {
      border-image-source: linear-gradient(45deg, #009EE3, #b1d6f5);
    }
    100% {
      border-image-source: linear-gradient(45deg, #b1d6f5, #009EE3);
    }
  }
}

